.search-group {
      display: flex;
      flex-grow: 1; /* Ocupa o espaço disponível no contêiner pai */
  }
  
  .form-control.busca {
      flex-grow: 1; /* Cresce para preencher o espaço disponível */
      width: auto; /* Garante que o campo de entrada possa crescer */
      max-width: 450px; /* Limita a largura máxima do campo de entrada */
  }
  
  
  .btn.btn-ligth {
      margin-left: auto; /* Empurra o botão para a extrema direita */
  }
  