.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: #5e5c5e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 1000; 
}

.logo {
    height: 30px;
    margin-right: auto; 
}

.logobody {
    height: 120px;
    margin-right: auto; 
}

.navbar {
    display: flex;
    gap: 15px;
}

.navbar a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.navbar a:hover {
    color: #ccc;
}

/* .body-content {
    position: relative;
    margin-top: 30px;
    height: calc(100vh - 30px);
    overflow: hidden; 
} */

body {
    width: 100%; 
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Para evitar barras de rolagem horizontal */
}

.body-content {
    background-color: #f7f7f7;
    position: relative;
    margin-top: 50px; /* Esta margem já leva em conta o cabeçalho fixo */
    overflow: auto; /* Isso permitirá o scroll caso o conteúdo exceda a altura */
}


.bacground-page {
    background-color: #95bcf2;
}

.plan-content {
    background-color: #ffffff;
    width: 80%; 
    position: relative;
    margin-top: 30px; /* Esta margem já leva em conta o cabeçalho fixo */
    margin-bottom:30px;
    overflow: auto; /* Isso permitirá o scroll caso o conteúdo exceda a altura */
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    object-fit: cover; 
    z-index: -1;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2rem;
    z-index: 1;
    text-align: center;
    background: rgba(0, 0, 0, 0.4); 
    padding: 10px;
    border-radius: 5px;
}

/* ... (mantenha as regras anteriores) ... */

/* .body-content {
    display: flex;
    margin-top: 70px;
    height: calc(100vh - 70px);
} */


.video-column, .text-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
}

.video-column, .text-column-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
}

.content-video {
    width: 80%;
    height: auto;
    border: 2px solid #ccc;
    border-radius: 10px;
}


.background-image {
    width: 100%;  
    height: 500px;  
    max-width: 400px; 
    background-size: cover; 
    background-position: center; 
    margin: 0 auto;       
    overflow: hidden;     
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center;
    min-height: 300px; 
}


.text-section {
    margin: 20px 0;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.main-title {
    font-size: 2rem;
    color: #333;
}

.sub-title {
    font-size: 1.2rem;
    color: #555;
}

.main-title-alt {
    font-size: 2.2rem;
    font-style: italic;
    color: #444;
}

.sub-title-alt {
    font-size: 1.3rem;
    font-weight: bold;
    color: #666;
}

.card {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.text-box {
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background-color: #fff; /* um cinza claro */
    max-width: 60%;  /* Isto limita a largura máxima da caixa */
    margin-left : auto; 
}


.text-box-left {
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background-color: #fff; /* um cinza claro */
    max-width: 60%;  /* Isto limita a largura máxima da caixa */
    margin-right : auto; 
}

.description {
    margin-top: 15px;
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
}

.home-container {
    overflow-x: hidden; 
    overflow-y: visible; 
    background-color: #f5a030; /* #95bcf2 */
    
 }
 

 *, *::before, *::after {
    box-sizing: border-box;
}

@media screen and (max-width: 767px) {
    .text-box {
        margin-left: auto;
        margin-right: auto;
        text-align: justify; /* Se você quiser centralizar o texto também */
        max-width: 80%; /* Usa a largura total disponível */
    }
}

@media screen and (max-width: 767px) {
    .text-box-left {
        margin-left: auto;
        margin-right: auto;
        text-align: justify; /* Se você quiser centralizar o texto também */
        max-width: 80%; /* Usa a largura total disponível */
    }
}



