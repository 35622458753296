.small-font-column {
    font-size: 8px;
}

.small-font-table {
    font-size: 7px;
}

.right-aligned-cell {
    display: flex;
    justify-content: flex-end;
    /* Alinha os itens à direita */
    width: 100%;
}

.menu-visible {
    width: 250px;
    /* ou a largura desejada */
    /* outros estilos */
}

.menu-hidden {
    width: 0;
    overflow: hidden;
    /* outros estilos */
}

.menu-visible,
.menu-hidden {
    transition: width 0.3s ease;
}

.expand-content {
    width: 100%;
    transition: width 0.3s ease;
    margin-left: 20px;
}

.container-tabela {
    width: 100%;

}

.app-container-local {
    width: 100%;
    min-width: 90vw;
}

.botao-coluna-direita {
    text-align: right;
}

.btn-cli {
    margin-right: 10px;
}