
.sidebar .footer{

    height: 65px;

}

.sidebar .tela-cad{
    height: calc(100% - 95px);
    overflow: auto;
    align-content: flex-start;

}

.sidebar h4{
    height: 30px;
    margin-bottom:0px ;
}