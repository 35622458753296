.modalResponsive {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100% !important;
}

  
  @media (max-width: 768px) {
    .modalResponsive {
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
      width: 100% !important;
      height: 100% !important;
      max-height: none !important;
      margin-top: 20 !important;
      margin-left: 0 !important;
    }
  }
  
  