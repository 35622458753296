
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 350px;
    margin: 50px auto;
}

.logo img {
    width: 100px;
    margin-bottom: 20px;
    
}

.logobody {
    max-width: 120%;  /* Isso garante que a imagem nunca exceda a largura do container */
    height: auto;     /* Isso mantém a proporção da imagem */
}



.login-form input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    background-color: #ddd;
}


.container {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center;     /* Se você também quiser centralizar verticalmente */
    padding: 5px;
    margin: 5px;
    
}
