.footer-container {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 5px 0;
    font-size: 0.7rem;
    max-height: 60px;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.social-links a {
    margin: 0 15px;
    color: #ecf0f1;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #3498db;
}

.company-details {
    display: flex;
    flex-direction: column;
    align-items: right;
}

.footer {
    /* Remova "position: fixed;" se estiver presente. */
    width: 100%;
    background-color: #464548;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.company-details p {
    margin: -2px 5px;
}


.footer, .footer-container, .social-links, .company-details {
    border: none;
}

.footer, .footer-container {
    overflow: visible;
}

.footer, .footer-container, .social-links, .company-details {
    margin: 0;
    padding: 0;
}

.footer, .footer-container {
    width: 100%;
    box-sizing: border-box; /* Isso garante que padding e border não adicionem largura extra */
}

.footer-container {
    overflow: hidden;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}
