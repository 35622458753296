@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100;1,200;1,300&display=swap');

body {
  overflow-x: hidden;
  font-family: "poppins";
  background-color: #f7f7f7;
}

select {
  border: 0px;
  background-color: #fff;

  cursor: pointer;
  width: 100%;
}

.no-data-found {
  height: 350px;
  padding-top: 220px;
}


/* Estilo base para o container */
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.adesao-container {
  overflow-x: hidden;
  overflow-y: visible;
  width: 80%;
}

.w-90 {
  width: 90% !important;
}

.title-card-conteiners {
  font-size: 2rem;
  color: #fff;   
 /* font-weight: bold;  /* font-style: bold; está incorreto, use font-weight */
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

 
.app-container {
  display: flex;
}  

 

.menu-visible {
  width: 250px;
  /* ou a largura desejada quando visível */
  transition: width 0.3s;
}

.menu-hidden {
  width: 0;
  overflow: hidden;
  transition: width 0.3s;
}

.content {
  flex-grow: 1;
}

/* Estilo para a fonte da tabela */
.custom-table {
  font-size: 8px;
  width: 100%;
}

/* Estilo base para o card */
.card {
  border-radius: 8px;
  /* Borda arredondada */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra leve para dar um efeito de elevação */
  overflow: hidden;
  /* Garante que nada saia fora das bordas arredondadas */
  transition: transform 0.3s;
  /* Animação suave para efeitos de hover */

}

.card:hover {
  transform: scale(1.05);
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
}


.card-title-alt {
  font-size: 1.6rem;
  font-style: normal;
  color: #444;
}

.card-sub-title-alt {
  font-size: 1.3rem;
  color: #666;
  border-bottom: 1px solid #000; /* Mude #000 para a cor que você desejar */
  padding-bottom: 5px;  /* Espaço entre o texto e a linha */
  margin-bottom: 10px;  /* Espaço após a linha */
}

.card-descricao-titulo {
  font-size: 1.3rem;
  color: #666;
  border-bottom: 1px solid #000; /* Mude #000 para a cor que você desejar */
  padding-bottom: 5px;  /* Espaço entre o texto e a linha */
  margin-bottom: 10px;  /* Espaço após a linha */
} 


.card-header {
  background-color: #007BFF;
  /* Cor de fundo azul padrão (pode alterar) */
  color: #ffffff;
  /* Cor do texto branco */
  font-size: 1.2rem;
  /* Tamanho da fonte */
  padding: 10px 15px;
  /* Espaçamento interno para o cabeçalho */
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-text {
  color: #777777;
  /* Cor de texto cinza */
}

/* Efeito hover para o card */


.cartao-body {
  height: 480px; /* Escolha a altura que deseja */
  overflow: auto; /* Se quiser que o conteúdo excedente gere uma barra de rolagem */
  /* overflow: hidden; /* Se quiser ocultar o conteúdo excedente */
}

.button-container {
  margin-top: 20px;
  align-self: flex-end;
  /* alinha o botão à direita */
}
 
.btn {
  margin-right: 1px; 
}

.btn:last-child {
  margin-right: 0;   
} 


.right-aligned-cell {
  display: flex;
  justify-content: flex-end; /* Alinha os itens à direita */
  width: 100%;
}

.menu-visible {
  width: 250px; /* ou a largura desejada */
  /* outros estilos */
}

.menu-hidden {
  width: 0;
  overflow: hidden;
  /* outros estilos */
}
